html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  font-family: 'Helvetica', sans-serif;
  font-size: 10px;
}
